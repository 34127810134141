import '@angular/compiler';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import {bootstrapApplication, BrowserModule} from "@angular/platform-browser";

import {environment} from './environments/environment';
import {AppComponent} from "./app/app.component";
import { provideRouter, Router } from '@angular/router';
import {ROUTES} from "app/routes";
import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {ThemeToggle} from "packages/theme/themeToggle";
import {SettingsProvider} from "editor/code/settings/settings-provider";
import {EventDispatcher} from "packages/event-bus/event.bus";
import {
  TUI_SANITIZER,
  TuiAlertModule,
  TuiDialogModule,
  TuiHostedDropdownModule,
  TuiRootModule,
  tuiSvgSrcInterceptors
} from "@taiga-ui/core";
import {DialogBackgroundFilter} from "packages/dialog-bf-filter/dialog.background-filter";
import {Profiler} from "auth/profiler";
import {Authenticator} from "auth/authenticator";
import {Translator} from "packages/i18n/translator";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import {HttpMockInterceptor} from "mock/mock-api.interceptor";
import {NGX_MONACO_EDITOR_CONFIG} from "editor/ngx-monaco-editor/config";
import {NgDompurifySanitizer} from "@tinkoff/ng-dompurify";
import {AppSvgInterceptor} from "icons/svg.interceptor";
import {provideTransloco, provideTranslocoLoadingTpl} from "@ngneat/transloco";
import {DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES} from "packages/i18n/types";
import {provideTranslocoPersistLang} from "@ngneat/transloco-persist-lang";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideTranslocoLocale} from "@ngneat/transloco-locale";
import { initSentry } from './sentry';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}
initSentry()

bootstrapApplication(
  AppComponent,
  {
    providers: [
      provideAnimations(),
      provideRouter(ROUTES),
      provideHttpClient(
        withInterceptorsFromDi(),
        withInterceptors([])
      ),
      importProvidersFrom([TuiRootModule, TuiAlertModule, TuiHostedDropdownModule, TuiDialogModule]),
      ThemeToggle,
      SettingsProvider,
      EventDispatcher,
      DialogBackgroundFilter,
      Profiler,
      Authenticator,
      Translator,
      SupabaseClient,

      {provide: HTTP_INTERCEPTORS, useClass: HttpMockInterceptor, multi: true},

      {
        provide: NGX_MONACO_EDITOR_CONFIG, useValue: {
          baseUrl: './assets',
          onMonacoLoad: () => {
            const monaco = (window as any).monaco;
          }
        },
      },

      // -----------------------------------
      // SENTRY
      // -----------------------------------
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: true,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      // -----------------------------------



      {
        provide: TUI_SANITIZER,
        useClass: NgDompurifySanitizer,
      },
      tuiSvgSrcInterceptors(AppSvgInterceptor),

      provideTranslocoLoadingTpl(`loading...`),
      provideTranslocoLocale(),
      provideTransloco({
        config: {
          availableLangs: SUPPORTED_LANGUAGES,
          defaultLang: DEFAULT_LANGUAGE,
          reRenderOnLangChange: true,
          fallbackLang: DEFAULT_LANGUAGE,
          prodMode: environment.production,
          flatten: {
            aot: environment.production,
          },
          missingHandler: {
            useFallbackTranslation: true,
          }
        },
      }),
      provideTranslocoPersistLang({
        storage: {
          useValue: localStorage,
        },
      }),
    ],
  }
).catch(err => console.error(err))

