import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {Observable, of, timer} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {listItem456, itemComments456, item456} from './data/records/456';

@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!environment.mock) {
      return next.handle(this.withEnvHost(request));
    }

    const method = request.method.toLowerCase()

    // Return mocked url if match
    // @ts-ignore
    const URLS = REQUESTS[method]
    if (!URLS) {
      return next.handle(this.withEnvHost(request));
    }

    // Моки для GET и POST
    for (const element of URLS) {
      if (request.url === element.url) {
        // GET берем из мока
        const mock = element.json

        let delayTime = 200 // 200 ms задержка по дефолту
        if ('delay' in element) {
          delayTime = element.delay
        }

        return timer(delayTime).pipe(
          switchMap(() => {
            return of(new HttpResponse({status: 200, body: mock}))
              .pipe(
                // subscribeOn(asyncScheduler),
                tap((res) => {
                  console.info('Mocked response for : ' + request.url, res);
                })
              )
          })
        )
      }
    }


    return next.handle(this.withEnvHost(request));
  }

  private withEnvHost(req: HttpRequest<any>): HttpRequest<any> {
    if (environment.host) {
      return req.clone({url: environment.host + req.url});
    }

    return req
  }
}

const GET = [
  {
    url: '/api/records',
    delay: 200,
    json: [
      // {
      //   "id": '123',
      //   "owner": 'fjdj54-5dffd-5ggg-dg45-54545',
      //   "name": "Собеседование Федоров М.А. Golang Middle",
      //   "status": 'finish'
      // },
      listItem456,
      // {
      //   "id": '44kl54-5dffd-5ggg-dg45-54545',
      //   "name": "Собеседование Лешего",
      //   "owner": 'fjdj54-5dffd-5ggg-dg45-54545',
      //   "status": 'finish'
      // },
    ]
  },

  item456,
  ...itemComments456,
  {
    url: '/api/records/45115/comments/9',
    delay: 1000,
    json: {
      "id": 9,
      "time": 49000,
      "body": "Which method is better to use in Golang for converting JSON into structs: json.Decode or json.Unmarshal, and in which cases is each one preferable?",
      "user": {
        "name": "Kris"
      },
      "select": {
        "startLineNumber": 2,
        "startColumn": 15,
        "endLineNumber": 4,
        "endColumn": 3,
      },
      "children": [
        {
          "id": 2,
          "body": "Потому что так удобнее обычно делать",
          "user": {
            "name": "Maks"
          },
          "children": [
            {
              "id": 3,
              "body": "Муть какая-то",
              "user": {
                "name": "Kris"
              }
            }
          ]
        },
        {
          "id": 4,
          "body": "В данном примере это не важно как делать",
          "user": {
            "name": "Emil"
          },
        },
      ]
    }
  },
]

const POST = [
  {
    url: "/api/records/45115/media",
    json: "ok",
  }
]

const REQUESTS = {
  get: GET,
  post: POST,
}

const REFLECT_REQUEST_FOR_POST =[
  {
    url: '/api/records/45115',
  }
]
