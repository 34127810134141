export const item456 = {
  url: '/api/records/45115',
  delay: 200,
  json: {
    "id": "45115",
    "name": "Собеседование Федоров М.А. Golang Middle (2)",
    "status": "init",
    "singleFile": false,
    "shareStatus": false,
    "shareLink": "3nb5v3vF4",
    "media": {
      "src": "https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3",
      "type": "audio/mp3"
    },
    "meta": {
      "time": {
        "start": 1660945262000,
        "end": 1660945362000
      },
      "value": {
        "value": "maks"
      },
      "eventsParts": [
        {
          "number": 0,
          "start": 1660945262000,
          "end": 1660945297637,
        },
        {
          "number": 1,
          "start": 1660945298852,
          "end": 1660945310465,
        },
        {
          "number": 2,
          "start": 1660945310482,
          "end": 1660945323992,
        },
        {
          "number": 3,
          "start": 1660945323995,
          "end": 1660945362000,
        },
      ],
    },
  },
}
