import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {DEFAULT_FONT_SIZE, DEFAULT_LANG, DEFAULT_SIDEBAR_WIDTH, EditorSettings} from "../../options";
import {buildChangesWithFirstValue, Changes} from "packages/utls/changedFields";

const SETTINGS_NAME = 'editor_settings'

const defaultSettings: EditorSettings = {
  language: DEFAULT_LANG,
  fontSize: DEFAULT_FONT_SIZE,
  speed: 1,
  isNightTheme: true,
  collapsedProjectSideBar: true,
  collapsedRightSideBar: true,
  projectSideBarWidth: DEFAULT_SIDEBAR_WIDTH,
  playerMute: false,
};

@Injectable({providedIn: 'root'})
export class SettingsProvider {

  public settings$: BehaviorSubject<EditorSettings> = this.load();
  public settingsChanges$ = new Subject<Changes<EditorSettings>>();

  save(newSettings: Partial<EditorSettings>) {
    console.log(3, newSettings.projectSideBarWidth);
    const oldSettings = this.settings$.value
    this.settings$.next({...oldSettings, ...newSettings})

    localStorage.setItem(SETTINGS_NAME, JSON.stringify(this.settings$.value))

    this.settingsChanges$.next(buildChangesWithFirstValue(this.settings$.value, oldSettings))
  }

  private load(): BehaviorSubject<EditorSettings> {
    const data = localStorage.getItem(SETTINGS_NAME);
    if (!data || data === '') {
      return new BehaviorSubject(defaultSettings)
    }

    const settings: EditorSettings = JSON.parse(data)
    if (!settings) {
      return new BehaviorSubject(defaultSettings)
    }

    return new BehaviorSubject({...defaultSettings, ...settings})
  }
}
