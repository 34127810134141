import {inject, Injectable} from "@angular/core";
import {TranslocoService} from "@ngneat/transloco";
import {DEFAULT_LANGUAGE, FLAG_LANGUAGE_MAP, Language, NAME_LANGUAGE_MAP} from "packages/i18n/types";
import {TuiCountryIsoCode} from "@taiga-ui/i18n";
import {BehaviorSubject, Observable} from "rxjs";
import {WINDOW} from "@ng-web-apis/common";

@Injectable({
  providedIn: 'root'
})
export class Translator {
  private translocoService: TranslocoService = inject(TranslocoService)
  private window: Window = inject(WINDOW)
  private _flag$ = new BehaviorSubject<TuiCountryIsoCode>(this.getCurrentFlag())
  private profileLangInit = false

  constructor() {
    this.translocoService.setFallbackLangForMissingTranslation({fallbackLang: DEFAULT_LANGUAGE})
    this.profileLangInit = this._getProfileLangInit()
  }

  public countryFlag(): Observable<TuiCountryIsoCode> {
    return this._flag$
  }

  public changeLang(lang: Language, profileInit = false) {
    if (lang === this.translocoService.getActiveLang()) {
      return
    }

    if (profileInit && this.profileLangInit) {
      return
    }

    if (profileInit) {
      this._setProfileLangInit(true)
    }

    this.translocoService.setActiveLang(lang)
    this._flag$.next(this.getCurrentFlag())
  }

  public getCurrentFlag(): TuiCountryIsoCode {
    return FLAG_LANGUAGE_MAP.get(<Language>this.translocoService.getActiveLang() || <Language>this.translocoService.getDefaultLang())
      || TuiCountryIsoCode.US
  }

  public getFlagByLang(lang: Language): TuiCountryIsoCode {
    return FLAG_LANGUAGE_MAP.get(lang) || this.getCurrentFlag()
  }

  public getNameBy(lang: Language): string {
    const name = NAME_LANGUAGE_MAP.get(lang)
    if (!name) {
      throw new Error('Invalid language')
    }

    return name
  }

  private detectLang() {

  }

  public logout() {
    this._setProfileLangInit(false)
  }

  private _setProfileLangInit(val: boolean) {
    this.window.localStorage.setItem('profileLangInit', String(val))
    this.profileLangInit = val
  }

  private _getProfileLangInit() {
    return Boolean(this.window.localStorage.getItem('profileLangInit')) || false
  }
}
