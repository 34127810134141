import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  Inject,
  Injector,
  OnInit, Output,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {SettingsProvider} from "editor/code/settings/settings-provider";
import {filter, takeUntil} from "rxjs/operators";
import {
  TuiDialogService,
  TuiRootModule
} from "@taiga-ui/core";
import {TuiDestroyService} from "@taiga-ui/cdk";
import {NetworkMonitor} from "packages/network-monitoring/network.monitor";
import {BehaviorSubject, Observable} from "rxjs";
import {DialogBackgroundFilter} from "packages/dialog-bf-filter/dialog.background-filter";
import {Authenticator} from "auth/authenticator";
import {WINDOW} from "@ng-web-apis/common";
import {NightComponent} from "app/night/NightComponent";
import {CommonModule} from "@angular/common";
import { ScrollableComponent } from 'app/scrollable/scrollable';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NightComponent,
    RouterOutlet,
    TuiRootModule,
    ScrollableComponent,
  ],
  providers: [
    TuiDestroyService,
  ],
})
export class AppComponent implements OnInit {
  public bgBlur$: Observable<boolean> = inject(DialogBackgroundFilter).blurCss$

  isNightTheme$ = new BehaviorSubject(false)
  private authenticator: Authenticator = inject(Authenticator)
  private window: Window = inject(WINDOW)
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute)

  scrollable$ = new BehaviorSubject(true)

  constructor(
    private _r: Router,
    protected router: Router,
    public settingsProvider: SettingsProvider,
    @Inject(Injector) private readonly injector: Injector,
    private networker: NetworkMonitor,
    @Inject(TuiDialogService) private dialogs: TuiDialogService,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
  ) {
    this.settingsProvider.settings$.subscribe(stgs => {
      this.isNightTheme$.next(stgs.isNightTheme)
    })
  }

  setScrollable(enable: boolean) {
    this.scrollable$.next(enable)
  }

  ngOnInit() {
    this.authenticator.authChanges((ch) => {
      this.authenticator.sessionUser$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(u => {
        if (ch === 'SIGNED_OUT' && u !== null) {
          this.window.location.reload()
        }
      })
    })

    this.router.events.pipe(
      filter(ev => ev instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe()
  }
}
