<app-night-theme *ngIf="isNightTheme$ | async"></app-night-theme>
<app-scrollable (onScrollableEnabled)="setScrollable($event)"></app-scrollable>

<tui-root style="overflow-x: visible">

  <div class="app_content"
       [class.app_content--blured]="bgBlur$ | async"
       [style.overflow]="(scrollable$ | async) ? 'auto' : 'hidden'"
       style="height: 100vh;"
  >
    <router-outlet></router-outlet>
  </div>



  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs-->
  </ng-container>
  <ng-container ngProjectAs="tuiOverNotifications">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>

</tui-root>


