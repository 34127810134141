import { Routes } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { HashMap, Translation } from '@ngneat/transloco/lib/types';

import { anonymous, authenticated } from 'auth/guard/auth.guard';
import { SUPPORTED_LANGUAGES } from 'packages/i18n/types';
import { provideReplayLangScope } from 'editor/replay/translation';
import { provideRecordLangScope } from 'editor/record/translations';
import { provideCabinetLangScope } from 'app/cabinet/translations';

export const provideAppLangScope = () => {
  return provideTranslocoScope({
    scope: 'app',
    alias: 'app',
    loader: SUPPORTED_LANGUAGES.reduce((acc: HashMap<() => Promise<Translation>>, lang) => {
      acc[lang] = () => import(`./i18n/${lang}.json`);
      return acc;
    }, {})
  })
}


export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./page.home/routes').then(m => m.HOME_ROUTES),
    providers: [
      provideAppLangScope(),
      provideRecordLangScope(),
      provideReplayLangScope(),
    ],
    canActivate: [anonymous],
  },
  {
    path: 'confirm',
    loadComponent: () => import(`../auth/confirm/confirm.page`).then(c => c.ConfirmPage),
    providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },
  {
    path: 'logout',
    loadComponent: () => import('../auth/logout/logout.page').then(c => c.LogoutPage),
    providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'app',
    loadChildren: () => import('./cabinet/routes').then(m => m.CABINET_ROUTES),
    providers: [
      provideAppLangScope(),
      provideCabinetLangScope(),
    ],
    canActivate: [authenticated],
  },

  {
    path: 'iframe',
    loadComponent: () => import('./page.demo/iframe.page').then(m => m.IframePage),
    canActivate: [anonymous],
  },


  {
    path: 'new',
    loadChildren: () => import('./page.new.record/routes').then(m => m.NEW_REPLAY_ROUTES),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(),
    ],
    canActivate: [authenticated],
  },
  {
    path: 'record',
    loadChildren: () => import('./page.record/routes').then(m => m.RECORD_ROUTES),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(), // TODO:: выпилить
      provideRecordLangScope(),
    ],
    canActivate: [authenticated],
  },
  {
    path: 'replay',
    loadChildren: () => import('./page.replay/routes').then(m => m.REPLAY_ROUTES),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(),
    ],
    canActivate: [anonymous],
  },
  {
    path: 'edit',
    loadChildren: () => import('./page.replay.edit/routes').then(m => m.EDIT_REPLAY_ROUTES),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'redirect/:provider',
    loadComponent: () => import('./redirect.auth/redirect.component').then(m => m.RedirectComponent),
    canActivate: [anonymous],
  },

  {
    path: 'pages',
    loadChildren: () => import('./page/page.routable/routes').then(m => m.PAGES_ROUTES),
    canActivate: [anonymous],
  },


  // not working
  {
    path: '**',
    loadChildren: () => import('./page.not-found/routes').then(m => m.NOT_FOUND__ROUTES),
    providers: [
      // TODO:: not working, but working with provide in NotFoundComponent
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  }
];
