import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Message } from './message';
import { MetaData } from './meta-data';
import {Injectable} from "@angular/core";

type ClassConstructor<T> = {
  new (...args: any[]): T;
};

/**
 * Main library class.
 *
 * @author Cristiam Mercado
 * @since 2.0.0
 * @version 5.2.0
 */
@Injectable(
// {
//   providedIn: "root"
// }
)
export class EventDispatcher {
  /**
   * Main observable to multicast to all observers.
   */
  private eventBus: Subject<Message>;

  /**
   * Key message separator.
   */
  private separator = ':';

  /**
   * Constructor for this class: Initializes event bus.
   */
  constructor() {
    this.eventBus = new Subject<Message>();
  }

  /**
   * Validates key matching.
   *
   * @param key Key to identify the message/event.
   * @param wildcard Wildcard received from on method.
   *
   * @return true if key matches, false otherwise.
   */
  public keyMatch(key: string, wildcard: string): boolean {
    const w = '*';
    const ww = '**';

    const partMatch = (wl: string, k: string): boolean => {
      return wl === w || wl === k;
    };

    const sep = this.separator;
    const kArr = key.split(sep);
    const wArr = wildcard.split(sep);

    const kLen = kArr.length;
    const wLen = wArr.length;
    const max = Math.max(kLen, wLen);

    for (let i = 0; i < max; i++) {
      const cK = kArr[i];
      const cW = wArr[i];

      if (cW === ww && typeof cK !== 'undefined') {
        return true;
      }

      if (!partMatch(cW, cK)) {
        return false;
      }
    }

    return true;
  }

  /**
   * Publish a message/event to event bus.
   *
   * @param  key Key to identify the message/event.
   * @param  [data] Optional: Additional data sent with the message/event.
   * @throws {Error} key parameter must be a string and must not be empty.
   */
  public dispatch<T>(key: string, data?: T): void {
    if (!key.trim().length) {
      throw new Error('key parameter must be a string and must not be empty');
    }

    const metadata: MetaData<T> = new MetaData<T>(key, data);

    this.eventBus.next({ key, metadata });
  }

  /**
   * Returns an observable you can subscribe to listen messages/events.
   *
   * @param key Key to identify the message/event.
   *
   * @return Observable you can subscribe to listen messages/events.
   */
  public on<T>(key: string): Observable<MetaData<T>> {
    return this.eventBus.asObservable().pipe(
      filter((event: Message) => this.keyMatch(event.key, key)),
      map((event: Message) => event.metadata)
    );
  }
}
