export const itemComments456 = [
  {
    url: '/api/records/45115/events/0',
    delay: 2000,
    json: {
      "id": "45115_0",
      "serialNumber": 0,
      "events": [
        {
          "t": 5,
          "p": {
            "ff": [
              {
                "id": 1,
                "n": "users.go",
                "t": "file",
                "v": "func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}"
              },

              {
                "id": 11,
                "n": "maks.svg",
                "t": "file",
                "v": "https://raw.githubusercontent.com/taiga-family/taiga-ui/87151e10f8502fee469d329ff7c38b0624f28aaf/projects/demo/src/assets/images/github.svg"
              },

              {
                "id": 2,
                "n": "src",
                "v": null,
                "t": "folder",
                "ch": [
                  {
                    "id": 21,
                    "n": "index.js",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 22,
                    "n": "index.ts",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 23,
                    "t": "file",
                    "n": ".cache"
                  },
                ]
              },
            ]
          },
          "tstm": 1660945262000,
          "id": 1,
        },
        {
          "t": 9,
          "id": 1,
          "p": "users.go",
          "tstm": 1660945262000,
        },
        {
          "t": 4,
          "p": "func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}",
          "tstm": 1660945262000,
          "id": 1,
        },
        {"t":1,"p":{"lineNumber":22,"column":2},"tstm":1660945268373,"id":1},
        {"t":0,"p":{"startLineNumber":22,"startColumn":2,"endLineNumber":22,"endColumn":2,"selectionStartLineNumber":22,"selectionStartColumn":2,"positionLineNumber":22,"positionColumn":2},"tstm":1660945268373,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":23},"tstm":1660945273722,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":23,"endLineNumber":1,"endColumn":23,"selectionStartLineNumber":1,"selectionStartColumn":23,"positionLineNumber":1,"positionColumn":23},"tstm":1660945273736,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":28},"tstm":1660945273902,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":19,"endLineNumber":1,"endColumn":28,"selectionStartLineNumber":1,"selectionStartColumn":19,"positionLineNumber":1,"positionColumn":28},"tstm":1660945273902,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":23},"tstm":1660945275052,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":23,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":23},"tstm":1660945275052,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":18},"tstm":1660945275985,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":18,"endLineNumber":2,"endColumn":18,"selectionStartLineNumber":2,"selectionStartColumn":18,"positionLineNumber":2,"positionColumn":18},"tstm":1660945275985,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":23},"tstm":1660945277301,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":23,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":23},"tstm":1660945277302,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":22},"tstm":1660945277517,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":22,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":22},"tstm":1660945277517,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":20},"tstm":1660945277532,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":20,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":20},"tstm":1660945277532,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":17},"tstm":1660945277549,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":17,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":17},"tstm":1660945277549,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":15},"tstm":1660945277566,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":15,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":15},"tstm":1660945277566,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":13},"tstm":1660945277583,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":13,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":13},"tstm":1660945277583,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":11},"tstm":1660945277599,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":11,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":11},"tstm":1660945277600,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":10},"tstm":1660945277616,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":10,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":10},"tstm":1660945277616,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":9},"tstm":1660945277649,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":9,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":9},"tstm":1660945277649,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":8},"tstm":1660945277682,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":8,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":8},"tstm":1660945277682,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":7},"tstm":1660945277782,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":7,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":7},"tstm":1660945277782,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":6},"tstm":1660945277816,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":6,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":6},"tstm":1660945277816,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":5},"tstm":1660945277865,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":5,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":5},"tstm":1660945277866,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":4},"tstm":1660945277899,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":4,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":4},"tstm":1660945277899,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":3},"tstm":1660945277965,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":3,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":3},"tstm":1660945277966,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":2},"tstm":1660945278182,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":2,"endLineNumber":2,"endColumn":23,"selectionStartLineNumber":2,"selectionStartColumn":23,"positionLineNumber":2,"positionColumn":2},"tstm":1660945278183,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":18},"tstm":1660945280737,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":18,"endLineNumber":2,"endColumn":18,"selectionStartLineNumber":2,"selectionStartColumn":18,"positionLineNumber":2,"positionColumn":18},"tstm":1660945280737,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":17},"tstm":1660945281384,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":17,"endLineNumber":2,"endColumn":17,"selectionStartLineNumber":2,"selectionStartColumn":17,"positionLineNumber":2,"positionColumn":17},"tstm":1660945281385,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":21},"tstm":1660945281601,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":11,"endLineNumber":2,"endColumn":21,"selectionStartLineNumber":2,"selectionStartColumn":11,"positionLineNumber":2,"positionColumn":21},"tstm":1660945281602,"id":1},
        {"t":1,"p":{"lineNumber":4,"column":26},"tstm":1660945282902,"id":1},
        {"t":0,"p":{"startLineNumber":4,"startColumn":26,"endLineNumber":4,"endColumn":26,"selectionStartLineNumber":4,"selectionStartColumn":26,"positionLineNumber":4,"positionColumn":26},"tstm":1660945282903,"id":1},
        {"t":1,"p":{"lineNumber":4,"column":29},"tstm":1660945283102,"id":1},
        {"t":0,"p":{"startLineNumber":4,"startColumn":24,"endLineNumber":4,"endColumn":29,"selectionStartLineNumber":4,"selectionStartColumn":24,"positionLineNumber":4,"positionColumn":29},"tstm":1660945283102,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":10},"tstm":1660945285537,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":10,"endLineNumber":9,"endColumn":10,"selectionStartLineNumber":9,"selectionStartColumn":10,"positionLineNumber":9,"positionColumn":10},"tstm":1660945285537,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":12},"tstm":1660945287120,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":12,"endLineNumber":9,"endColumn":12,"selectionStartLineNumber":9,"selectionStartColumn":12,"positionLineNumber":9,"positionColumn":12},"tstm":1660945287120,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":14},"tstm":1660945287301,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":9,"endLineNumber":9,"endColumn":14,"selectionStartLineNumber":9,"selectionStartColumn":9,"positionLineNumber":9,"positionColumn":14},"tstm":1660945287301,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":24},"tstm":1660945289184,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":24,"endLineNumber":9,"endColumn":24,"selectionStartLineNumber":9,"selectionStartColumn":24,"positionLineNumber":9,"positionColumn":24},"tstm":1660945289184,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":64},"tstm":1660945290252,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":64,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":64},"tstm":1660945290252,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":63},"tstm":1660945290350,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":63,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":63},"tstm":1660945290350,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":61},"tstm":1660945290366,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":61,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":61},"tstm":1660945290366,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":60},"tstm":1660945290383,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":60,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":60},"tstm":1660945290383,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":57},"tstm":1660945290400,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":57,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":57},"tstm":1660945290400,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":55},"tstm":1660945290416,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":55,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":55},"tstm":1660945290416,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":54},"tstm":1660945290432,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":54,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":54},"tstm":1660945290432,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":52},"tstm":1660945290449,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":52,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":52},"tstm":1660945290449,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":51},"tstm":1660945290465,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":51,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":51},"tstm":1660945290465,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":50},"tstm":1660945290486,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":50,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":50},"tstm":1660945290486,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":49},"tstm":1660945290499,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":49,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":49},"tstm":1660945290499,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":48},"tstm":1660945290515,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":48,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":48},"tstm":1660945290516,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":47},"tstm":1660945290549,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":47,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":47},"tstm":1660945290549,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":46},"tstm":1660945290582,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":46,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":46},"tstm":1660945290583,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":45},"tstm":1660945290599,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":45,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":45},"tstm":1660945290599,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":44},"tstm":1660945290632,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":44,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":44},"tstm":1660945290632,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":43},"tstm":1660945290665,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":43,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":43},"tstm":1660945290665,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":42},"tstm":1660945290699,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":42,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":42},"tstm":1660945290699,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":41},"tstm":1660945290748,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":41,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":41},"tstm":1660945290748,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":40},"tstm":1660945290798,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":40,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":40},"tstm":1660945290798,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":39},"tstm":1660945290849,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":39,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":39},"tstm":1660945290849,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":38},"tstm":1660945290948,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":38,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":38},"tstm":1660945290949,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":37},"tstm":1660945291133,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":37,"endLineNumber":9,"endColumn":64,"selectionStartLineNumber":9,"selectionStartColumn":64,"positionLineNumber":9,"positionColumn":37},"tstm":1660945291134,"id":1},
        {"t":1,"p":{"lineNumber":19,"column":3},"tstm":1660945294136,"id":1},
        {"t":0,"p":{"startLineNumber":19,"startColumn":3,"endLineNumber":19,"endColumn":3,"selectionStartLineNumber":19,"selectionStartColumn":3,"positionLineNumber":19,"positionColumn":3},"tstm":1660945294137,"id":1},
        {"t":1,"p":{"lineNumber":18,"column":2},"tstm":1660945294300,"id":1},
        {"t":0,"p":{"startLineNumber":18,"startColumn":2,"endLineNumber":19,"endColumn":3,"selectionStartLineNumber":19,"selectionStartColumn":3,"positionLineNumber":18,"positionColumn":2},"tstm":1660945294300,"id":1},
        {"t":1,"p":{"lineNumber":17,"column":2},"tstm":1660945294349,"id":1},
        {"t":0,"p":{"startLineNumber":17,"startColumn":2,"endLineNumber":19,"endColumn":3,"selectionStartLineNumber":19,"selectionStartColumn":3,"positionLineNumber":17,"positionColumn":2},"tstm":1660945294350,"id":1},
        {"t":1,"p":{"lineNumber":16,"column":2},"tstm":1660945294449,"id":1},
        {"t":0,"p":{"startLineNumber":16,"startColumn":2,"endLineNumber":19,"endColumn":3,"selectionStartLineNumber":19,"selectionStartColumn":3,"positionLineNumber":16,"positionColumn":2},"tstm":1660945294449,"id":1},
        {"t":1,"p":{"lineNumber":21,"column":16},"tstm":1660945296603,"id":1},
        {"t":0,"p":{"startLineNumber":21,"startColumn":16,"endLineNumber":21,"endColumn":16,"selectionStartLineNumber":21,"selectionStartColumn":16,"positionLineNumber":21,"positionColumn":16},"tstm":1660945296603,"id":1},
        {"t":1,"p":{"lineNumber":21,"column":26},"tstm":1660945296801,"id":1},
        {"t":0,"p":{"startLineNumber":21,"startColumn":4,"endLineNumber":21,"endColumn":26,"selectionStartLineNumber":21,"selectionStartColumn":4,"positionLineNumber":21,"positionColumn":26},"tstm":1660945296802,"id":1},
        {"t":1,"p":{"lineNumber":18,"column":9},"tstm":1660945297635,"id":1},
        {"t":0,"p":{"startLineNumber":18,"startColumn":9,"endLineNumber":18,"endColumn":9,"selectionStartLineNumber":18,"selectionStartColumn":9,"positionLineNumber":18,"positionColumn":9},"tstm":1660945297636,"id":1},
        {"t":1,"p":{"lineNumber":22,"column":2},"tstm":1660945297637,"id":1},
      ]
    }
  },
  {
    url: '/api/records/45115/events/1',
    delay: 2000,
    json: {
      "id": "45115_1",
      "serialNumber": 1,
      "events": [
        {
          "t": 5,
          "p": {
            "ff": [
              {
                "id": 1,
                "n": "users.go",
                "t": "file",
                "v": "func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}"
              },

              {
                "id": 11,
                "n": "maks.svg",
                "t": "file",
                "v": "https://raw.githubusercontent.com/taiga-family/taiga-ui/87151e10f8502fee469d329ff7c38b0624f28aaf/projects/demo/src/assets/images/github.svg"
              },

              {
                "id": 2,
                "n": "src",
                "v": null,
                "t": "folder",
                "ch": [
                  {
                    "id": 21,
                    "n": "index.js",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 22,
                    "n": "index.ts",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 23,
                    "t": "file",
                    "n": ".cache"
                  },
                ]
              },
            ]
          },
          "tstm": 1660945298852,
          "id": 1,
        },
        {
          "t": 9,
          "id": 1,
          "p": "users.go",
          "tstm": 1660945298852,
        },

        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}", "tstm":1660945298852,"id":1},
        {"t":0,"p":{"startLineNumber":22,"startColumn":2,"endLineNumber":22,"endColumn":2,"selectionStartLineNumber":22,"selectionStartColumn":2,"positionLineNumber":22,"positionColumn":2},"tstm":1660945298852,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n","tstm":1660945299493,"id":1},
        {"t":1,"p":{"lineNumber":23,"column":1},"tstm":1660945299495,"id":1},
        {"t":0,"p":{"startLineNumber":23,"startColumn":1,"endLineNumber":23,"endColumn":1,"selectionStartLineNumber":23,"selectionStartColumn":1,"positionLineNumber":23,"positionColumn":1},"tstm":1660945299495,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\n","tstm":1660945299675,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":1},"tstm":1660945299677,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":1,"endLineNumber":24,"endColumn":1,"selectionStartLineNumber":24,"selectionStartColumn":1,"positionLineNumber":24,"positionColumn":1},"tstm":1660945299678,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nf","tstm":1660945300123,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":2},"tstm":1660945300124,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":2,"endLineNumber":24,"endColumn":2,"selectionStartLineNumber":24,"selectionStartColumn":2,"positionLineNumber":24,"positionColumn":2},"tstm":1660945300125,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfu","tstm":1660945300242,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":3},"tstm":1660945300242,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":3,"endLineNumber":24,"endColumn":3,"selectionStartLineNumber":24,"selectionStartColumn":3,"positionLineNumber":24,"positionColumn":3},"tstm":1660945300242,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfun","tstm":1660945300444,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":4},"tstm":1660945300445,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":4,"endLineNumber":24,"endColumn":4,"selectionStartLineNumber":24,"selectionStartColumn":4,"positionLineNumber":24,"positionColumn":4},"tstm":1660945300445,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc","tstm":1660945300688,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":5},"tstm":1660945300689,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":5,"endLineNumber":24,"endColumn":5,"selectionStartLineNumber":24,"selectionStartColumn":5,"positionLineNumber":24,"positionColumn":5},"tstm":1660945300690,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc ","tstm":1660945301239,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":6},"tstm":1660945301240,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":6,"endLineNumber":24,"endColumn":6,"selectionStartLineNumber":24,"selectionStartColumn":6,"positionLineNumber":24,"positionColumn":6},"tstm":1660945301241,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc m","tstm":1660945301540,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":7},"tstm":1660945301541,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":7,"endLineNumber":24,"endColumn":7,"selectionStartLineNumber":24,"selectionStartColumn":7,"positionLineNumber":24,"positionColumn":7},"tstm":1660945301541,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc ma","tstm":1660945301622,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":8},"tstm":1660945301623,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":8,"endLineNumber":24,"endColumn":8,"selectionStartLineNumber":24,"selectionStartColumn":8,"positionLineNumber":24,"positionColumn":8},"tstm":1660945301623,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc mak","tstm":1660945301761,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":9},"tstm":1660945301762,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":9,"endLineNumber":24,"endColumn":9,"selectionStartLineNumber":24,"selectionStartColumn":9,"positionLineNumber":24,"positionColumn":9},"tstm":1660945301763,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks","tstm":1660945301844,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":10},"tstm":1660945301845,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":10,"endLineNumber":24,"endColumn":10,"selectionStartLineNumber":24,"selectionStartColumn":10,"positionLineNumber":24,"positionColumn":10},"tstm":1660945301845,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks()","tstm":1660945302227,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":11},"tstm":1660945302230,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":11,"endLineNumber":24,"endColumn":11,"selectionStartLineNumber":24,"selectionStartColumn":11,"positionLineNumber":24,"positionColumn":11},"tstm":1660945302231,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks()","tstm":1660945302393,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":12},"tstm":1660945302394,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":12,"endLineNumber":24,"endColumn":12,"selectionStartLineNumber":24,"selectionStartColumn":12,"positionLineNumber":24,"positionColumn":12},"tstm":1660945302394,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() ","tstm":1660945302487,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":13},"tstm":1660945302488,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":13,"endLineNumber":24,"endColumn":13,"selectionStartLineNumber":24,"selectionStartColumn":13,"positionLineNumber":24,"positionColumn":13},"tstm":1660945302489,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() s","tstm":1660945302681,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":14},"tstm":1660945302682,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":14,"endLineNumber":24,"endColumn":14,"selectionStartLineNumber":24,"selectionStartColumn":14,"positionLineNumber":24,"positionColumn":14},"tstm":1660945302682,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() st","tstm":1660945302887,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":15},"tstm":1660945302888,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":15,"endLineNumber":24,"endColumn":15,"selectionStartLineNumber":24,"selectionStartColumn":15,"positionLineNumber":24,"positionColumn":15},"tstm":1660945302889,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() str","tstm":1660945302971,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":16},"tstm":1660945302972,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":16,"endLineNumber":24,"endColumn":16,"selectionStartLineNumber":24,"selectionStartColumn":16,"positionLineNumber":24,"positionColumn":16},"tstm":1660945302972,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() stri","tstm":1660945303718,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":17},"tstm":1660945303719,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":17,"endLineNumber":24,"endColumn":17,"selectionStartLineNumber":24,"selectionStartColumn":17,"positionLineNumber":24,"positionColumn":17},"tstm":1660945303720,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() strin","tstm":1660945303936,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":18},"tstm":1660945303937,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":18,"endLineNumber":24,"endColumn":18,"selectionStartLineNumber":24,"selectionStartColumn":18,"positionLineNumber":24,"positionColumn":18},"tstm":1660945303938,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string","tstm":1660945304089,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":19},"tstm":1660945304090,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":19,"endLineNumber":24,"endColumn":19,"selectionStartLineNumber":24,"selectionStartColumn":19,"positionLineNumber":24,"positionColumn":19},"tstm":1660945304090,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string ","tstm":1660945304183,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":20},"tstm":1660945304184,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":20,"endLineNumber":24,"endColumn":20,"selectionStartLineNumber":24,"selectionStartColumn":20,"positionLineNumber":24,"positionColumn":20},"tstm":1660945304185,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {}","tstm":1660945304537,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":21},"tstm":1660945304540,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":21,"endLineNumber":24,"endColumn":21,"selectionStartLineNumber":24,"selectionStartColumn":21,"positionLineNumber":24,"positionColumn":21},"tstm":1660945304540,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {}","tstm":1660945304773,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":22},"tstm":1660945304775,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":22,"endLineNumber":24,"endColumn":22,"selectionStartLineNumber":24,"selectionStartColumn":22,"positionLineNumber":24,"positionColumn":22},"tstm":1660945304776,"id":1},
        {"t":1,"p":{"lineNumber":24,"column":21},"tstm":1660945305531,"id":1},
        {"t":0,"p":{"startLineNumber":24,"startColumn":21,"endLineNumber":24,"endColumn":21,"selectionStartLineNumber":24,"selectionStartColumn":21,"positionLineNumber":24,"positionColumn":21},"tstm":1660945305531,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    \n}","tstm":1660945305776,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":5},"tstm":1660945305779,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":5,"endLineNumber":25,"endColumn":5,"selectionStartLineNumber":25,"selectionStartColumn":5,"positionLineNumber":25,"positionColumn":5},"tstm":1660945305779,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    r\n}","tstm":1660945306953,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":6},"tstm":1660945306954,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":6,"endLineNumber":25,"endColumn":6,"selectionStartLineNumber":25,"selectionStartColumn":6,"positionLineNumber":25,"positionColumn":6},"tstm":1660945306954,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    re\n}","tstm":1660945306990,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":7},"tstm":1660945306991,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":7,"endLineNumber":25,"endColumn":7,"selectionStartLineNumber":25,"selectionStartColumn":7,"positionLineNumber":25,"positionColumn":7},"tstm":1660945306991,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    ret\n}","tstm":1660945307223,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":8},"tstm":1660945307225,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":8,"endLineNumber":25,"endColumn":8,"selectionStartLineNumber":25,"selectionStartColumn":8,"positionLineNumber":25,"positionColumn":8},"tstm":1660945307225,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    retu\n}","tstm":1660945307394,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":9},"tstm":1660945307396,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":9,"endLineNumber":25,"endColumn":9,"selectionStartLineNumber":25,"selectionStartColumn":9,"positionLineNumber":25,"positionColumn":9},"tstm":1660945307396,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    retur\n}","tstm":1660945307497,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":10},"tstm":1660945307498,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":10,"endLineNumber":25,"endColumn":10,"selectionStartLineNumber":25,"selectionStartColumn":10,"positionLineNumber":25,"positionColumn":10},"tstm":1660945307498,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    return\n}","tstm":1660945307619,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":11},"tstm":1660945307620,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":11,"endLineNumber":25,"endColumn":11,"selectionStartLineNumber":25,"selectionStartColumn":11,"positionLineNumber":25,"positionColumn":11},"tstm":1660945307621,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}\n\nfunc maks() string {\n    return \n}","tstm":1660945307705,"id":1},
        {"t":1,"p":{"lineNumber":25,"column":12},"tstm":1660945307706,"id":1},
        {"t":0,"p":{"startLineNumber":25,"startColumn":12,"endLineNumber":25,"endColumn":12,"selectionStartLineNumber":25,"selectionStartColumn":12,"positionLineNumber":25,"positionColumn":12},"tstm":1660945307707,"id":1},
        {"t":1,"p":{"lineNumber":21,"column":32},"tstm":1660945310135,"id":1},
        {"t":0,"p":{"startLineNumber":21,"startColumn":32,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":21,"positionColumn":32},"tstm":1660945310136,"id":1},
        {"t":1,"p":{"lineNumber":20,"column":1},"tstm":1660945310232,"id":1},
        {"t":0,"p":{"startLineNumber":20,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":20,"positionColumn":1},"tstm":1660945310232,"id":1},
        {"t":1,"p":{"lineNumber":19,"column":3},"tstm":1660945310265,"id":1},
        {"t":0,"p":{"startLineNumber":19,"startColumn":3,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":19,"positionColumn":3},"tstm":1660945310265,"id":1},
        {"t":1,"p":{"lineNumber":18,"column":9},"tstm":1660945310283,"id":1},
        {"t":0,"p":{"startLineNumber":18,"startColumn":9,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":18,"positionColumn":9},"tstm":1660945310284,"id":1},
        {"t":1,"p":{"lineNumber":17,"column":10},"tstm":1660945310299,"id":1},
        {"t":0,"p":{"startLineNumber":17,"startColumn":10,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":17,"positionColumn":10},"tstm":1660945310299,"id":1},
        {"t":1,"p":{"lineNumber":16,"column":9},"tstm":1660945310315,"id":1},
        {"t":0,"p":{"startLineNumber":16,"startColumn":9,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":16,"positionColumn":9},"tstm":1660945310316,"id":1},
        {"t":1,"p":{"lineNumber":16,"column":6},"tstm":1660945310332,"id":1},
        {"t":0,"p":{"startLineNumber":16,"startColumn":6,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":16,"positionColumn":6},"tstm":1660945310332,"id":1},
        {"t":1,"p":{"lineNumber":15,"column":1},"tstm":1660945310349,"id":1},
        {"t":0,"p":{"startLineNumber":15,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":15,"positionColumn":1},"tstm":1660945310349,"id":1},
        {"t":1,"p":{"lineNumber":14,"column":2},"tstm":1660945310366,"id":1},
        {"t":0,"p":{"startLineNumber":14,"startColumn":2,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":14,"positionColumn":2},"tstm":1660945310366,"id":1},
        {"t":1,"p":{"lineNumber":13,"column":1},"tstm":1660945310382,"id":1},
        {"t":0,"p":{"startLineNumber":13,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":13,"positionColumn":1},"tstm":1660945310382,"id":1},
        {"t":1,"p":{"lineNumber":12,"column":1},"tstm":1660945310399,"id":1},
        {"t":0,"p":{"startLineNumber":12,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":12,"positionColumn":1},"tstm":1660945310400,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":1},"tstm":1660945310421,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":11,"positionColumn":1},"tstm":1660945310421,"id":1},
        {"t":1,"p":{"lineNumber":10,"column":1},"tstm":1660945310433,"id":1},
        {"t":0,"p":{"startLineNumber":10,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":10,"positionColumn":1},"tstm":1660945310433,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":1},"tstm":1660945310465,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":9,"positionColumn":1},"tstm":1660945310465,"id":1}
      ]
    }
  },
  {
    url: '/api/records/45115/events/2',
    delay: 200,
    json: {
      "id": "45115_2",
      "serialNumber": 2,
      "events": [

        {
          "t": 5,
          "p": {
            "ff": [
              {
                "id": 1,
                "n": "users.go",
                "t": "file",
                "v": "func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}"
              },

              {
                "id": 11,
                "n": "maks.svg",
                "t": "file",
                "v": "https://raw.githubusercontent.com/taiga-family/taiga-ui/87151e10f8502fee469d329ff7c38b0624f28aaf/projects/demo/src/assets/images/github.svg"
              },

              {
                "id": 2,
                "n": "src",
                "v": null,
                "t": "folder",
                "ch": [
                  {
                    "id": 21,
                    "n": "index.js",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 22,
                    "n": "index.ts",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 23,
                    "t": "file",
                    "n": ".cache"
                  },
                ]
              },
            ]
          },
          "tstm": 1660945310482,
          "id": 1,
        },
        {
          "t": 9,
          "id": 1,
          "p": "users.go",
          "tstm": 1660945310482,
        },
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}", "tstm":1660945310482,"id":1},
        {"t":1,"p":{"lineNumber":8,"column":1},"tstm":1660945310482,"id":1},
        {"t":0,"p":{"startLineNumber":8,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":8,"positionColumn":1},"tstm":1660945310482,"id":1},
        {"t":1,"p":{"lineNumber":7,"column":1},"tstm":1660945310531,"id":1},
        {"t":0,"p":{"startLineNumber":7,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":7,"positionColumn":1},"tstm":1660945310532,"id":1},
        {"t":1,"p":{"lineNumber":6,"column":1},"tstm":1660945310681,"id":1},
        {"t":0,"p":{"startLineNumber":6,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":6,"positionColumn":1},"tstm":1660945310682,"id":1},
        {"t":1,"p":{"lineNumber":5,"column":1},"tstm":1660945310782,"id":1},
        {"t":0,"p":{"startLineNumber":5,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":5,"positionColumn":1},"tstm":1660945310782,"id":1},
        {"t":1,"p":{"lineNumber":4,"column":1},"tstm":1660945310794,"id":1},
        {"t":0,"p":{"startLineNumber":4,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":4,"positionColumn":1},"tstm":1660945310794,"id":1},
        {"t":1,"p":{"lineNumber":3,"column":1},"tstm":1660945310806,"id":1},
        {"t":0,"p":{"startLineNumber":3,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":3,"positionColumn":1},"tstm":1660945310806,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":1},"tstm":1660945310818,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":2,"positionColumn":1},"tstm":1660945310818,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":1},"tstm":1660945310819,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":1,"positionColumn":1},"tstm":1660945310819,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":1},"tstm":1660945310832,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":2,"positionColumn":1},"tstm":1660945310832,"id":1},
        {"t":1,"p":{"lineNumber":3,"column":1},"tstm":1660945310881,"id":1},
        {"t":0,"p":{"startLineNumber":3,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":3,"positionColumn":1},"tstm":1660945310882,"id":1},
        {"t":1,"p":{"lineNumber":4,"column":1},"tstm":1660945310916,"id":1},
        {"t":0,"p":{"startLineNumber":4,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":4,"positionColumn":1},"tstm":1660945310916,"id":1},
        {"t":1,"p":{"lineNumber":5,"column":1},"tstm":1660945310933,"id":1},
        {"t":0,"p":{"startLineNumber":5,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":5,"positionColumn":1},"tstm":1660945310933,"id":1},
        {"t":1,"p":{"lineNumber":6,"column":1},"tstm":1660945310981,"id":1},
        {"t":0,"p":{"startLineNumber":6,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":6,"positionColumn":1},"tstm":1660945310982,"id":1},
        {"t":1,"p":{"lineNumber":7,"column":1},"tstm":1660945310998,"id":1},
        {"t":0,"p":{"startLineNumber":7,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":7,"positionColumn":1},"tstm":1660945310998,"id":1},
        {"t":1,"p":{"lineNumber":8,"column":1},"tstm":1660945311032,"id":1},
        {"t":0,"p":{"startLineNumber":8,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":8,"positionColumn":1},"tstm":1660945311032,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":1},"tstm":1660945311066,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":9,"positionColumn":1},"tstm":1660945311066,"id":1},
        {"t":1,"p":{"lineNumber":10,"column":1},"tstm":1660945311082,"id":1},
        {"t":0,"p":{"startLineNumber":10,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":10,"positionColumn":1},"tstm":1660945311082,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":1},"tstm":1660945311117,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":11,"positionColumn":1},"tstm":1660945311118,"id":1},
        {"t":1,"p":{"lineNumber":12,"column":1},"tstm":1660945311148,"id":1},
        {"t":0,"p":{"startLineNumber":12,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":12,"positionColumn":1},"tstm":1660945311148,"id":1},
        {"t":1,"p":{"lineNumber":13,"column":1},"tstm":1660945311182,"id":1},
        {"t":0,"p":{"startLineNumber":13,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":13,"positionColumn":1},"tstm":1660945311182,"id":1},
        {"t":1,"p":{"lineNumber":14,"column":1},"tstm":1660945311248,"id":1},
        {"t":0,"p":{"startLineNumber":14,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":14,"positionColumn":1},"tstm":1660945311249,"id":1},
        {"t":1,"p":{"lineNumber":13,"column":1},"tstm":1660945311466,"id":1},
        {"t":0,"p":{"startLineNumber":13,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":13,"positionColumn":1},"tstm":1660945311466,"id":1},
        {"t":1,"p":{"lineNumber":12,"column":1},"tstm":1660945311482,"id":1},
        {"t":0,"p":{"startLineNumber":12,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":12,"positionColumn":1},"tstm":1660945311483,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":1},"tstm":1660945311516,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":11,"positionColumn":1},"tstm":1660945311516,"id":1},
        {"t":1,"p":{"lineNumber":10,"column":1},"tstm":1660945311549,"id":1},
        {"t":0,"p":{"startLineNumber":10,"startColumn":1,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":10,"positionColumn":1},"tstm":1660945311549,"id":1},
        {"t":1,"p":{"lineNumber":10,"column":2},"tstm":1660945311567,"id":1},
        {"t":0,"p":{"startLineNumber":10,"startColumn":2,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":10,"positionColumn":2},"tstm":1660945311567,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":2},"tstm":1660945311598,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":2,"endLineNumber":21,"endColumn":32,"selectionStartLineNumber":21,"selectionStartColumn":32,"positionLineNumber":9,"positionColumn":2},"tstm":1660945311598,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\t\n}\n\nfunc maks() string {\n    return \n}","tstm":1660945312286,"id":1},
        {"t":1,"p":{"lineNumber":9,"column":2},"tstm":1660945312288,"id":1},
        {"t":0,"p":{"startLineNumber":9,"startColumn":2,"endLineNumber":9,"endColumn":2,"selectionStartLineNumber":9,"selectionStartColumn":2,"positionLineNumber":9,"positionColumn":2},"tstm":1660945312289,"id":1},
        {"t":1,"p":{"lineNumber":8,"column":1},"tstm":1660945314018,"id":1},
        {"t":0,"p":{"startLineNumber":8,"startColumn":1,"endLineNumber":8,"endColumn":1,"selectionStartLineNumber":8,"selectionStartColumn":1,"positionLineNumber":8,"positionColumn":1},"tstm":1660945314018,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\t\n}\n\nfunc maks() string {\n    return \n}","tstm":1660945314320,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return \n}","tstm":1660945314910,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":12},"tstm":1660945315652,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":12,"endLineNumber":11,"endColumn":12,"selectionStartLineNumber":11,"selectionStartColumn":12,"positionLineNumber":11,"positionColumn":12},"tstm":1660945315653,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return f\n}","tstm":1660945316798,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":13},"tstm":1660945316799,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":13,"endLineNumber":11,"endColumn":13,"selectionStartLineNumber":11,"selectionStartColumn":13,"positionLineNumber":11,"positionColumn":13},"tstm":1660945316799,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fm\n}","tstm":1660945316905,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":14},"tstm":1660945316906,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":14,"endLineNumber":11,"endColumn":14,"selectionStartLineNumber":11,"selectionStartColumn":14,"positionLineNumber":11,"positionColumn":14},"tstm":1660945316907,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt\n}","tstm":1660945317100,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":15},"tstm":1660945317101,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":15,"endLineNumber":11,"endColumn":15,"selectionStartLineNumber":11,"selectionStartColumn":15,"positionLineNumber":11,"positionColumn":15},"tstm":1660945317102,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.\n}","tstm":1660945317339,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":16},"tstm":1660945317340,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":16,"endLineNumber":11,"endColumn":16,"selectionStartLineNumber":11,"selectionStartColumn":16,"positionLineNumber":11,"positionColumn":16},"tstm":1660945317340,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.S\n}","tstm":1660945318638,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":17},"tstm":1660945318640,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":17,"endLineNumber":11,"endColumn":17,"selectionStartLineNumber":11,"selectionStartColumn":17,"positionLineNumber":11,"positionColumn":17},"tstm":1660945318640,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sp\n}","tstm":1660945318741,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":18},"tstm":1660945318742,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":18,"endLineNumber":11,"endColumn":18,"selectionStartLineNumber":11,"selectionStartColumn":18,"positionLineNumber":11,"positionColumn":18},"tstm":1660945318743,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Spr\n}","tstm":1660945319061,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":19},"tstm":1660945319062,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":19,"endLineNumber":11,"endColumn":19,"selectionStartLineNumber":11,"selectionStartColumn":19,"positionLineNumber":11,"positionColumn":19},"tstm":1660945319063,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Spri\n}","tstm":1660945319193,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":20},"tstm":1660945319195,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":20,"endLineNumber":11,"endColumn":20,"selectionStartLineNumber":11,"selectionStartColumn":20,"positionLineNumber":11,"positionColumn":20},"tstm":1660945319195,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprin\n}","tstm":1660945319363,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":21},"tstm":1660945319364,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":21,"endLineNumber":11,"endColumn":21,"selectionStartLineNumber":11,"selectionStartColumn":21,"positionLineNumber":11,"positionColumn":21},"tstm":1660945319365,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprint\n}","tstm":1660945319707,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":22},"tstm":1660945319708,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":22,"endLineNumber":11,"endColumn":22,"selectionStartLineNumber":11,"selectionStartColumn":22,"positionLineNumber":11,"positionColumn":22},"tstm":1660945319709,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf\n}","tstm":1660945319982,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":23},"tstm":1660945319983,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":23,"endLineNumber":11,"endColumn":23,"selectionStartLineNumber":11,"selectionStartColumn":23,"positionLineNumber":11,"positionColumn":23},"tstm":1660945319983,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf()\n}","tstm":1660945320327,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":24},"tstm":1660945320330,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":24,"endLineNumber":11,"endColumn":24,"selectionStartLineNumber":11,"selectionStartColumn":24,"positionLineNumber":11,"positionColumn":24},"tstm":1660945320330,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf()\n}","tstm":1660945320477,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":25},"tstm":1660945320479,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":25,"endLineNumber":11,"endColumn":25,"selectionStartLineNumber":11,"selectionStartColumn":25,"positionLineNumber":11,"positionColumn":25},"tstm":1660945320480,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":24},"tstm":1660945320920,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":24,"endLineNumber":11,"endColumn":24,"selectionStartLineNumber":11,"selectionStartColumn":24,"positionLineNumber":11,"positionColumn":24},"tstm":1660945320920,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"\")\n}","tstm":1660945321627,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":25},"tstm":1660945321630,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":25,"endLineNumber":11,"endColumn":25,"selectionStartLineNumber":11,"selectionStartColumn":25,"positionLineNumber":11,"positionColumn":25},"tstm":1660945321631,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"$\")\n}","tstm":1660945322707,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":26},"tstm":1660945322709,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":26,"endLineNumber":11,"endColumn":26,"selectionStartLineNumber":11,"selectionStartColumn":26,"positionLineNumber":11,"positionColumn":26},"tstm":1660945322710,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"$s\")\n}","tstm":1660945323066,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":27},"tstm":1660945323067,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":27,"endLineNumber":11,"endColumn":27,"selectionStartLineNumber":11,"selectionStartColumn":27,"positionLineNumber":11,"positionColumn":27},"tstm":1660945323068,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"$\")\n}","tstm":1660945323992,"id":1},
      ]
    }
  },
  {
    url: '/api/records/45115/events/3',
    delay: 600,
    json: {
      "id": "45115_3",
      "serialNumber": 3,
      "events": [

        {
          "t": 5,
          "p": {
            "ff": [
              {
                "id": 1,
                "n": "users.go",
                "t": "file",
                "v": "func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}"
              },

              {
                "id": 11,
                "n": "maks.svg",
                "t": "file",
                "v": "https://raw.githubusercontent.com/taiga-family/taiga-ui/87151e10f8502fee469d329ff7c38b0624f28aaf/projects/demo/src/assets/images/github.svg"
              },

              {
                "id": 2,
                "n": "src",
                "v": null,
                "t": "folder",
                "ch": [
                  {
                    "id": 21,
                    "n": "index.js",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 22,
                    "n": "index.ts",
                    "t": "file",
                    "v": "import bla bla bla"
                  },
                  {
                    "id": 23,
                    "t": "file",
                    "n": ".cache"
                  },
                ]
              },
            ]
          },
          "tstm": 1660945323995,
          "id": 1,
        },
        {
          "t": 9,
          "id": 1,
          "p": "users.go",
          "tstm": 1660945323995,
        },

        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n\n\tu := h.users.GetByInputCredentials(input.Email, input.Password)\n\tif u == nil {\n\t\tw.WriteHeader(http.StatusUnauthorized)\n\t\thttp.Error(w, \"user not found\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\tif !u.Activated() {\n\t\thttp.Error(w, \"user not activated\", http.StatusUnauthorized)\n\t\treturn\n\t}\n\n\th.writePairTokenResponse(w, u)\n}", "tstm":1660945323995,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":26},"tstm":1660945323995,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":26,"endLineNumber":11,"endColumn":26,"selectionStartLineNumber":11,"selectionStartColumn":26,"positionLineNumber":11,"positionColumn":26},"tstm":1660945323995,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"\")\n}","tstm":1660945324189,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":25},"tstm":1660945324192,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":25,"endLineNumber":11,"endColumn":25,"selectionStartLineNumber":11,"selectionStartColumn":25,"positionLineNumber":11,"positionColumn":25},"tstm":1660945324193,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%\")\n}","tstm":1660945324911,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":26},"tstm":1660945324913,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":26,"endLineNumber":11,"endColumn":26,"selectionStartLineNumber":11,"selectionStartColumn":26,"positionLineNumber":11,"positionColumn":26},"tstm":1660945324913,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\")\n}","tstm":1660945325396,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":27},"tstm":1660945325398,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":27,"endLineNumber":11,"endColumn":27,"selectionStartLineNumber":11,"selectionStartColumn":27,"positionLineNumber":11,"positionColumn":27},"tstm":1660945325398,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":28},"tstm":1660945325656,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":28,"endLineNumber":11,"endColumn":28,"selectionStartLineNumber":11,"selectionStartColumn":28,"positionLineNumber":11,"positionColumn":28},"tstm":1660945325657,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\",)\n}","tstm":1660945326383,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":29},"tstm":1660945326384,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":29,"endLineNumber":11,"endColumn":29,"selectionStartLineNumber":11,"selectionStartColumn":29,"positionLineNumber":11,"positionColumn":29},"tstm":1660945326384,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\", )\n}","tstm":1660945326466,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":30},"tstm":1660945326467,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":30,"endLineNumber":11,"endColumn":30,"selectionStartLineNumber":11,"selectionStartColumn":30,"positionLineNumber":11,"positionColumn":30},"tstm":1660945326467,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\", \"\")\n}","tstm":1660945326765,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":31},"tstm":1660945326767,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":31,"endLineNumber":11,"endColumn":31,"selectionStartLineNumber":11,"selectionStartColumn":31,"positionLineNumber":11,"positionColumn":31},"tstm":1660945326768,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\", \"M\")\n}","tstm":1660945327484,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":32},"tstm":1660945327486,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":32,"endLineNumber":11,"endColumn":32,"selectionStartLineNumber":11,"selectionStartColumn":32,"positionLineNumber":11,"positionColumn":32},"tstm":1660945327486,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\", \"Ma\")\n}","tstm":1660945327684,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":33},"tstm":1660945327685,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":33,"endLineNumber":11,"endColumn":33,"selectionStartLineNumber":11,"selectionStartColumn":33,"positionLineNumber":11,"positionColumn":33},"tstm":1660945327685,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\", \"Mak\")\n}","tstm":1660945327804,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":34},"tstm":1660945327805,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":34,"endLineNumber":11,"endColumn":34,"selectionStartLineNumber":11,"selectionStartColumn":34,"positionLineNumber":11,"positionColumn":34},"tstm":1660945327805,"id":1},
        {"t":4,"p":"func (h *Handler) UserLogin(w http.ResponseWriter, req *http.Request) {\n\tinput := LoginCreds{}\n\tdec := json.NewDecoder(req.Body)\n\tif err := dec.Decode(&input); err != nil {\n\t\thttp.Error(w, \"email and password not satisfied\", http.StatusBadRequest)\n\t\treturn\n\t}\n}\n\nfunc maks() string {\n    return fmt.Sprintf(\"%s\", \"Maks\")\n}","tstm":1660945327970,"id":1},
        {"t":1,"p":{"lineNumber":11,"column":35},"tstm":1660945327971,"id":1},
        {"t":0,"p":{"startLineNumber":11,"startColumn":35,"endLineNumber":11,"endColumn":35,"selectionStartLineNumber":11,"selectionStartColumn":35,"positionLineNumber":11,"positionColumn":35},"tstm":1660945327972,"id":1},
        {"t":1,"p":{"lineNumber":12,"column":2},"tstm":1660945329052,"id":1},
        {"t":0,"p":{"startLineNumber":12,"startColumn":2,"endLineNumber":12,"endColumn":2,"selectionStartLineNumber":12,"selectionStartColumn":2,"positionLineNumber":12,"positionColumn":2},"tstm":1660945329052,"id":1},
        {"t":1,"p":{"lineNumber":12,"column":2},"tstm":1660945331134,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":1,"endLineNumber":12,"endColumn":2,"selectionStartLineNumber":1,"selectionStartColumn":1,"positionLineNumber":12,"positionColumn":2},"tstm":1660945331134,"id":1},
        {"t":4,"p":"1","tstm":1660945331929,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":2},"tstm":1660945331932,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":2,"endLineNumber":1,"endColumn":2,"selectionStartLineNumber":1,"selectionStartColumn":2,"positionLineNumber":1,"positionColumn":2},"tstm":1660945331933,"id":1},
        {"t":4,"p":"11","tstm":1660945332116,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":3},"tstm":1660945332117,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":3,"endLineNumber":1,"endColumn":3,"selectionStartLineNumber":1,"selectionStartColumn":3,"positionLineNumber":1,"positionColumn":3},"tstm":1660945332118,"id":1},
        {"t":4,"p":"111","tstm":1660945332595,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":4},"tstm":1660945332597,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":4,"endLineNumber":1,"endColumn":4,"selectionStartLineNumber":1,"selectionStartColumn":4,"positionLineNumber":1,"positionColumn":4},"tstm":1660945332597,"id":1},
        {"t":4,"p":"1111","tstm":1660945332773,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":5},"tstm":1660945332774,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":5,"endLineNumber":1,"endColumn":5,"selectionStartLineNumber":1,"selectionStartColumn":5,"positionLineNumber":1,"positionColumn":5},"tstm":1660945332774,"id":1},
        {"t":4,"p":"11111","tstm":1660945332995,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":6},"tstm":1660945332996,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":6,"endLineNumber":1,"endColumn":6,"selectionStartLineNumber":1,"selectionStartColumn":6,"positionLineNumber":1,"positionColumn":6},"tstm":1660945332997,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":6},"tstm":1660945333559,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":1,"endLineNumber":1,"endColumn":6,"selectionStartLineNumber":1,"selectionStartColumn":1,"positionLineNumber":1,"positionColumn":6},"tstm":1660945333559,"id":1},
        {"t":4,"p":"","tstm":1660945334022,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":1},"tstm":1660945334024,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":1,"endLineNumber":1,"endColumn":1,"selectionStartLineNumber":1,"selectionStartColumn":1,"positionLineNumber":1,"positionColumn":1},"tstm":1660945334024,"id":1},
        {"t":4,"p":"f","tstm":1660945334782,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":2},"tstm":1660945334784,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":2,"endLineNumber":1,"endColumn":2,"selectionStartLineNumber":1,"selectionStartColumn":2,"positionLineNumber":1,"positionColumn":2},"tstm":1660945334784,"id":1},
        {"t":4,"p":"fu","tstm":1660945334918,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":3},"tstm":1660945334919,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":3,"endLineNumber":1,"endColumn":3,"selectionStartLineNumber":1,"selectionStartColumn":3,"positionLineNumber":1,"positionColumn":3},"tstm":1660945334919,"id":1},
        {"t":4,"p":"fun","tstm":1660945335100,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":4},"tstm":1660945335102,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":4,"endLineNumber":1,"endColumn":4,"selectionStartLineNumber":1,"selectionStartColumn":4,"positionLineNumber":1,"positionColumn":4},"tstm":1660945335102,"id":1},
        {"t":4,"p":"func","tstm":1660945335234,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":5},"tstm":1660945335235,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":5,"endLineNumber":1,"endColumn":5,"selectionStartLineNumber":1,"selectionStartColumn":5,"positionLineNumber":1,"positionColumn":5},"tstm":1660945335235,"id":1},
        {"t":4,"p":"func ","tstm":1660945335428,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":6},"tstm":1660945335429,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":6,"endLineNumber":1,"endColumn":6,"selectionStartLineNumber":1,"selectionStartColumn":6,"positionLineNumber":1,"positionColumn":6},"tstm":1660945335429,"id":1},
        {"t":4,"p":"func f","tstm":1660945336023,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":7},"tstm":1660945336025,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":7,"endLineNumber":1,"endColumn":7,"selectionStartLineNumber":1,"selectionStartColumn":7,"positionLineNumber":1,"positionColumn":7},"tstm":1660945336025,"id":1},
        {"t":4,"p":"func fo","tstm":1660945336320,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":8},"tstm":1660945336321,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":8,"endLineNumber":1,"endColumn":8,"selectionStartLineNumber":1,"selectionStartColumn":8,"positionLineNumber":1,"positionColumn":8},"tstm":1660945336321,"id":1},
        {"t":4,"p":"func foo","tstm":1660945337615,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":9},"tstm":1660945337618,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":9,"endLineNumber":1,"endColumn":9,"selectionStartLineNumber":1,"selectionStartColumn":9,"positionLineNumber":1,"positionColumn":9},"tstm":1660945337618,"id":1},
        {"t":4,"p":"func foo()","tstm":1660945338232,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":10},"tstm":1660945338234,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":10,"endLineNumber":1,"endColumn":10,"selectionStartLineNumber":1,"selectionStartColumn":10,"positionLineNumber":1,"positionColumn":10},"tstm":1660945338234,"id":1},
        {"t":4,"p":"func foo()","tstm":1660945338389,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":11},"tstm":1660945338392,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":11,"endLineNumber":1,"endColumn":11,"selectionStartLineNumber":1,"selectionStartColumn":11,"positionLineNumber":1,"positionColumn":11},"tstm":1660945338393,"id":1},
        {"t":4,"p":"func foo() ","tstm":1660945338519,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":12},"tstm":1660945338521,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":12,"endLineNumber":1,"endColumn":12,"selectionStartLineNumber":1,"selectionStartColumn":12,"positionLineNumber":1,"positionColumn":12},"tstm":1660945338521,"id":1},
        {"t":4,"p":"func foo() {}","tstm":1660945339116,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":13},"tstm":1660945339119,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":13,"endLineNumber":1,"endColumn":13,"selectionStartLineNumber":1,"selectionStartColumn":13,"positionLineNumber":1,"positionColumn":13},"tstm":1660945339119,"id":1},
        {"t":4,"p":"func foo() {\n    \n}","tstm":1660945339331,"id":1},
        {"t":1,"p":{"lineNumber":2,"column":5},"tstm":1660945339333,"id":1},
        {"t":0,"p":{"startLineNumber":2,"startColumn":5,"endLineNumber":2,"endColumn":5,"selectionStartLineNumber":2,"selectionStartColumn":5,"positionLineNumber":2,"positionColumn":5},"tstm":1660945339333,"id":1},
        {"t":1,"p":{"lineNumber":3,"column":2},"tstm":1660945340745,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":1,"endLineNumber":3,"endColumn":2,"selectionStartLineNumber":1,"selectionStartColumn":1,"positionLineNumber":3,"positionColumn":2},"tstm":1660945340746,"id":1},
        {"t":4,"p":"r","tstm":1660945342009,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":2},"tstm":1660945342011,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":2,"endLineNumber":1,"endColumn":2,"selectionStartLineNumber":1,"selectionStartColumn":2,"positionLineNumber":1,"positionColumn":2},"tstm":1660945342011,"id":1},
        {"t":4,"p":"rr","tstm":1660945342158,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":3},"tstm":1660945342160,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":3,"endLineNumber":1,"endColumn":3,"selectionStartLineNumber":1,"selectionStartColumn":3,"positionLineNumber":1,"positionColumn":3},"tstm":1660945342160,"id":1},
        {"t":4,"p":"rrr","tstm":1660945342298,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":4},"tstm":1660945342299,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":4,"endLineNumber":1,"endColumn":4,"selectionStartLineNumber":1,"selectionStartColumn":4,"positionLineNumber":1,"positionColumn":4},"tstm":1660945342299,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":1},"tstm":1660945343178,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":1,"endLineNumber":1,"endColumn":1,"selectionStartLineNumber":1,"selectionStartColumn":1,"positionLineNumber":1,"positionColumn":1},"tstm":1660945343178,"id":1},
        {"t":4,"p":"/rrr","tstm":1660945343441,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":2},"tstm":1660945343442,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":2,"endLineNumber":1,"endColumn":2,"selectionStartLineNumber":1,"selectionStartColumn":2,"positionLineNumber":1,"positionColumn":2},"tstm":1660945343442,"id":1},
        {"t":4,"p":"//rrr","tstm":1660945343590,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":3},"tstm":1660945343592,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":3,"endLineNumber":1,"endColumn":3,"selectionStartLineNumber":1,"selectionStartColumn":3,"positionLineNumber":1,"positionColumn":3},"tstm":1660945343592,"id":1},
        {"t":4,"p":"// rrr","tstm":1660945343898,"id":1},
        {"t":1,"p":{"lineNumber":1,"column":4},"tstm":1660945343899,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":4,"endLineNumber":1,"endColumn":4,"selectionStartLineNumber":1,"selectionStartColumn":4,"positionLineNumber":1,"positionColumn":4},"tstm":1660945343899,"id":1},
        {"t":0,"p":{"startLineNumber":1,"startColumn":4,"endLineNumber":1,"endColumn":4,"selectionStartLineNumber":1,"selectionStartColumn":4,"positionLineNumber":1,"positionColumn":4},"tstm":1660945362000,"id":1}
      ]
    }
  },
  {
    url: '/api/records/45115/comments',
    delay: 2000,
    json: [

      {
        "id": 55555555,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 6,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 7,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 8,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },
      {
        "id": 9,
        "time": 49000,
        "body": "Which method is better to use in Golang for converting JSON into structs: json.Decode or json.Unmarshal, and in which cases is each one preferable?",
        "user": {
          "name": "Kris"
        },
        "select": {
          "startLineNumber": 2,
          "startColumn": 15,
          "endLineNumber": 4,
          "endColumn": 3,
        },
        "children": [
          {
            "id": 2,
            "body": "Потому что так удобнее обычно делать",
            "user": {
              "name": "Maks"
            },
            "time": 49000,
            "select": {
              "startLineNumber": 2,
              "startColumn": 15,
              "endLineNumber": 4,
              "endColumn": 3,
            },
            "children": [
              {
                "id": 3,
                "body": "Муть какая-то",
                "user": {
                  "name": "Kris"
                }
              }
            ]
          },
          {
            "id": 4,
            "body": "В данном примере это не важно как делать",
            "user": {
              "name": "Emil"
            },
          },
        ]
      },


      {
        "id": 51,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 61,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 71,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 81,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },

      {
        "id": 5,
        "time": 57000,
        "select": {
          "startLineNumber": 2,
          "startColumn": 15,
          "endLineNumber": 3,
          "endColumn": 1,
        },
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris вы правы, тут он не нужен, просто для образца был показан"
      },

      {
        "id": 52,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 62,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 72,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 82,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },
      {
        "id": 53,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 63,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 73,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 83,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },
      {
        "id": 5555555555,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 556,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 557,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 558,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },

      {
        "id": 5551,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 5561,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 5571,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 5581,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },
      {
        "id": 559,
        "time": 49000,
        "body": "Which method is better to use in Golang for converting JSON into structs: json.Decode or json.Unmarshal, and in which cases is each one preferable?",
        "user": {
          "name": "Kris"
        },
        "select": {
          "startLineNumber": 2,
          "startColumn": 15,
          "endLineNumber": 4,
          "endColumn": 3,
        },
        "children": [
          {
            "id": 552,
            "body": "Потому что так удобнее обычно делать",
            "user": {
              "name": "Maks"
            },
            "children": [
              {
                "id": 553,
                "body": "Муть какая-то",
                "user": {
                  "name": "Kris"
                }
              }
            ]
          },
          {
            "id": 554,
            "body": "В данном примере это не важно как делать",
            "user": {
              "name": "Emil"
            },
          },
        ]
      },

      {
        "id": 555,
        "time": 57000,
        "select": {
          "startLineNumber": 2,
          "startColumn": 15,
          "endLineNumber": 3,
          "endColumn": 1,
        },
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris вы правы, тут он не нужен, просто для образца был показан"
      },

      {
        "id": 5552,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 5562,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 5572,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 5582,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      },
      {
        "id": 5553,
        "user": {
          "name": "Maksim Fedorov"
        },
        "body": "@Kris потому что имею право"
      },
      {
        "id": 5563,
        "user": {
          "name": "Vladimir"
        },
        "body": "Какое же говно этот ваш Golang и JavaScript"
      },
      {
        "id": 5573,
        "user": {
          "name": "Jackson"
        },
        "body": "+1, тоже не понимаю хайпа вокруг этого языка. На коде видно, как обрабатываются ошибки каждый раз. куда лучше использовать try/catch"
      },
      {
        "id": 5583,
        "user": {
          "name": "Kris"
        },
        "body": "@Maksim Fedorov, спасибо, подскажите, а если токен не прийдет корректный, какая ошибка будет? 404 или 403 нужно?"
      }
    ],
  },
]
