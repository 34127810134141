import {provideTranslocoScope} from "@ngneat/transloco";
import {HashMap, Translation} from "@ngneat/transloco/lib/types";

import {SUPPORTED_LANGUAGES} from "packages/i18n/types";

export const provideReplayLangScope = () => {
  return provideTranslocoScope({
    scope: 'replay',
    alias: 'replay',
    loader: SUPPORTED_LANGUAGES.reduce((acc: HashMap<() => Promise<Translation>>, lang) => {
      acc[lang] = () => import(`./i18n/${lang}.json`);
      return acc;
    }, {})
  })
}
