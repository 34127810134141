import {editor} from "monaco-editor";
import IEditorOptions = editor.IEditorOptions;
import {NgxEditorModel} from "editor/ngx-monaco-editor/types";

export interface EditorSettings {
  isNightTheme: boolean
  language: string
  fontSize: number
  speed: number

  //--
  collapsedProjectSideBar: boolean
  projectSideBarWidth: number
  //--
  collapsedRightSideBar: boolean
  //--

  playerMute: boolean
}

export const EMPTY_EDITOR_VALUE = ''

export const DEFAULT_SIDEBAR_WIDTH = 200
export const MIN_SIDEBAR_WIDTH = 60;
export const MAX_SIDEBAR_WIDTH = 300;

export const DEFAULT_FONT_SIZE= 15
export const DEFAULT_LANG = 'go'

export const LANGUAGES = [
  'text',
  'clojure',
  'coffee',
  'cpp',
  'csharp',
  'css',
  'dart',
  'dockerfile',
  'elixir',
  'fsharp',
  'go',
  'graphql',
  'html',
  'ini',
  'java',
  'javascript',
  'json',
  'julia',
  'kotlin',
  'less',
  'lua',
  'm3',
  'markdown',
  'mips',
  'msdax',
  'mysql',
  'objective-c',
  'pascal',
  'pascaligo',
  'perl',
  'pgsql',
  'php',
  'pla',
  'postiats',
  'powerquery',
  'powershell',
  'protobuf',
  'pug',
  'python',
  'qsharp',
  'r',
  'razor',
  'redis',
  'redshift',
  'restructuredtext',
  'ruby',
  'rust',
  'sb',
  'scala',
  'scheme',
  'scss',
  'shell',
  'solidity',
  'sophia',
  'sparql',
  'sql',
  'st',
  'swift',
  'systemverilog',
  'tcl',
  'twig',
  'typescript',
  'vb',
  'wgsl',
  'xml',
  'yaml'
];

type theme = {
  theme: string
}
export type EditorOpts = IEditorOptions & NgxEditorModel

export const DEFAULT_EDITOR_OPTIONS: EditorOpts = {

  // -------------------
  // Gutter hide
  // @ts-ignore
  lineNumbers: 'on',
  // folding: false,
  // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
  lineDecorationsWidth: 4,
  lineNumbersMinChars: 2,
  // -------------------

  // contextmenu: false,

  language: 'text',
  fontSize: 16,
  lineHeight: 1.6,
  value: '',
  hover: {enabled: false},
  // renderWhitespace: 'none',
  wordWrap: 'on',
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
    // verticalScrollbarSize: 0,
    alwaysConsumeMouseWheel: false,
    useShadows: false,
  },


  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  fontFamily: 'JetBrains Mono',
  minimap: {
    enabled: false,
  },

  renderLineHighlightOnlyWhenFocus: false,
  suggestOnTriggerCharacters: false,
  quickSuggestions: false,
  parameterHints: {
    enabled: false
  },
  acceptSuggestionOnEnter: "off",
  tabCompletion: "off",
  folding: false,
  foldingHighlight: false,
  selectionHighlight: false,
  selectOnLineNumbers: false,
  automaticLayout: false,
  scrollBeyondLastLine: false,
  hideCursorInOverviewRuler: true,
  renderLineHighlight: "none",
  cursorWidth: 2,
  occurrencesHighlight: false,

  matchBrackets: "near",

  guides: {
    indentation: false,
  },

  padding: {
    top: 0,
  },
};

