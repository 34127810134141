export const environment = {
  production: true,
  mock: true,
  enableLogger: false,

  host: 'https://codiew.io',
  mediaUploadHost: 'https://upload.codiew.io/files',
  mediaCDNHost: 'https://cdn.codiew.io',

  zoomVideoSDKKey: 'zJieIReqzLePLwATNdtaSOVz8exPpL2L68YD',

  LIMIT_DIALOG_RECORD : 60 * 1000,
  LIMIT_SHORT : 10 * 60 * 1000,
  LIMIT_LONG : 5*60 * 60 * 1000,

  sentryDSN: "https://af73a786df664fd0d31685ac62ead6b5@o765960.ingest.us.sentry.io/4507297981726720",
  supabaseUrl: 'https://rmjhetvaehxyalhvvfkw.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJtamhldHZhZWh4eWFsaHZ2Zmt3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5NDczNDMsImV4cCI6MjAxMzUyMzM0M30.xkvtexOdyGRjO_TBXN6vJabxGTImY8T1lfOlNIpg0cw',
};
