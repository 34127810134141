import {inject} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn} from '@angular/router';
import {from, of} from "rxjs";
import {catchError, filter, map, switchMap} from "rxjs/operators";
import {Authenticator} from "auth/authenticator";

/**
 *
 */
export const authenticated: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router: Router = inject(Router)
  const authenticator: Authenticator = inject(Authenticator)

  return authenticator
    .passport()
    .pipe(
      switchMap(u => {
        if (u.isAnon()) {
          return router.navigate(['/login'], {queryParams: {returnUrl: state.url}})
        }

        return of(true)
      })
    )
}


/**
 *
 */
export const notLoggedOrReturn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router: Router = inject(Router)
  const authenticator: Authenticator = inject(Authenticator)

  return authenticator.passport()
    .pipe(
      switchMap(u => {
        if (!u.isAnon()) {
          // TODO:: redirect to return url
          return router.navigate(["/"])
        }

        return of(true)
      })
    )
}


export const anonymous: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(Authenticator).passport()
    .pipe(
      map(_ => {
        return true
      }),
      catchError(err => {
        return of(true)
      })
    )
}
